<template>
  <div class="w-full">
    <form @submit.prevent="submitBusinessPackage">
      <app-form-select
        class="w-full mb-5"
        v-model="business.package"
        :datas="packages"
        :labelText="$t('business.package.title')"
        :capitalize="true"
        :loading="loading"
        :errorMessage="errors.package"
        :disabled="editMode"
      />
      <app-form-input
        id="business.referral_code"
        name="business.referral_code"
        class="w-full mb-5"
        type="text"
        :labelText="$t('business.package.referral_code')"
        v-model="referral_code"
        :errorMessage="errors.referral_code"
        :disabled="editMode"
      />
      <app-form-input
        id="business.internal_voucher"
        name="business.internal_voucher"
        class="w-full mb-5"
        type="text"
        :labelText="$t('business.package.promo_code')"
        v-model="internal_voucher"
        :errorMessage="errors.internal_voucher"
        :disabled="editMode"
      />
      <app-button
        type="submit"
        v-if="!editMode"
        class="w-full"
        :loading="loading"
        :disabled="!hasChanged"
        :showf70Icon="false"
      >
        {{ $t("general.save") }}
      </app-button>
    </form>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  props: ["menu_action", "edit_mode"],
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      init_referral_code: null,
      init_internal_voucher: null,
      referral_code: null,
      internal_voucher: null,
      hasChanged: false,
      emptyErrors: {
        package: [],
        referral_code: [],
        internal_voucher: [],
      },
    };
  },
  async mounted() {
    this.getPackages();
    this.init_referral_code = this.business?.referral_code?.referral_code;
    this.init_internal_voucher = this.business?.internal_voucher?.name;
    this.referral_code = this.business?.referral_code?.referral_code;
    this.internal_voucher = this.business?.internal_voucher?.name;
  },
  watch: {
    business: {
      handler: function () {
        this.hasChanged = true;
      },
      deep: true,
    },
    referral_code: {
      handler: function () {
        if (this.init_referral_code != this.referral_code) {
          this.hasChanged = true;
        }
      },
    },
    internal_voucher: {
      handler: function () {
        if (this.init_internal_voucher != this.internal_voucher) {
          this.hasChanged = true;
        }
      },
    },
  },
  computed: {
    packages() {
      return this.$store.getters["referenceStore/packages"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    loading() {
      return this.$store.getters["businessStore/loading"];
    },
    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    editMode: {
      get() {
        return this.edit_mode;
      },
      set(v) {
        this.$emit("update:edit_mode", v);
      },
    },
  },
  methods: {
    async getPackages() {
      this.$store.commit("referenceStore/resetState");
      this.$store.dispatch("referenceStore/getPackages");
    },
    async submitBusinessPackage() {
      this.resetState();

      const businessPackage = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessPackage",
        {
          business_id: this.business.id,
          package: this.business.package.id,
          referral_code: this.referral_code ?? null,
          internal_voucher: this.internal_voucher ?? null,
        }
      );

      if (businessPackage != null) {
        this.hasChanged = false;
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          5000
        );
      }
    },
    editAction() {
      this.editMode = !this.editMode;

      if (this.editMode == false) {
        this.$store.dispatch(
          "businessStore/getBusinessById",
          this.$route.params.business_id
        );
      }
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>
